import "core-js/modules/es.object.has-own.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("b-modal", {
    attrs: {
      "hide-header": "",
      "hide-footer": "",
      centered: "",
      "no-close-on-backdrop": "",
      "no-stacking": "",
      size: "xl"
    },
    model: {
      value: _vm.showDialog,
      callback: function ($$v) {
        _vm.showDialog = $$v;
      },
      expression: "showDialog"
    }
  }, [_c("b-row", {
    staticClass: "mb-4",
    attrs: {
      "align-h": "between"
    }
  }, [_c("b-col", {
    attrs: {
      cols: "10"
    }
  }, [_c("img", {
    attrs: {
      src: require("@/assets/images/icon_logo_violet.svg"),
      alt: ""
    }
  })]), _c("b-col", {
    staticClass: "text-right"
  }, [_c("a", {
    staticClass: "tw-text-eclipse",
    attrs: {
      href: "javascript:;"
    },
    on: {
      click: function ($event) {
        _vm.showDialog = false;
      }
    }
  }, [_c("b-icon", {
    attrs: {
      icon: "x-circle",
      "font-scale": "1.5"
    }
  })], 1)])], 1), _c("b-row", [_c("b-col", {
    staticClass: "text-primary",
    attrs: {
      cols: "2"
    }
  }, [_vm._v(" Prescription Logs ")]), _c("b-col", [_c("b-row", [_c("b-col", {
    staticStyle: {
      "max-height": "80vh",
      overflow: "auto"
    }
  }, [_c("span", {
    staticClass: "text-primary"
  }, [_vm._v("Active Prescription")]), _c("br"), _vm._l(_vm.prescriptionData, function (prescription) {
    return _c("b-card", {
      key: prescription.patientid + prescription.id,
      staticClass: "tw-cursor-pointer tw-relative tw-rounded-md card-container",
      class: _vm.getCSSClasses(prescription),
      attrs: {
        "border-variant": "light"
      },
      on: {
        click: function ($event) {
          return _vm.openPrescriptionLog(prescription);
        }
      }
    }, [_c("div", {
      staticClass: "tw-flex tw-items-start tw-mb-3"
    }, [_c("div", {
      staticClass: "tw-flex-1 tw-space-y-2 tw-mb-2"
    }, [_c("div", [_c("h2", {
      staticClass: "tw-m-0 tw-font-atten tw-text-xl tw-text-[#19191C] tw-font-bold"
    }, [_vm._v(" " + _vm._s(prescription.medicinename) + " ")]), _c("p", {
      staticClass: "tw-m-0 tw-text-sm"
    }, [_vm._v(" " + _vm._s(prescription.dosage) + " ")])]), _c("div", [_c("h4", {
      staticClass: "card-status mb-0 tw-text-sm tw-leading-snug"
    }, [_vm._v(" " + _vm._s(_vm.getRefillString(prescription)) + " ")]), _c("h4", {
      staticClass: "tw-text-[#828282] tw-text-sm tw-leading-snug mb-0"
    }, [_vm._v(" " + _vm._s(prescription.unit) + " ")])])]), _c("div", {
      staticClass: "tw-flex-1 tw-text-right tw-pl-2"
    }, [_c("p", {
      staticClass: "text-primary card-status tw-mb-0 tw-capitalize tw-flex tw-items-center tw-justify-end"
    }, [_c("span", {
      staticClass: "tw-capitalize"
    }, [prescription.prescriptionstatus === "CANCELLED" ? [Object.hasOwn(prescription, "can_restart") ? [_vm._v(" " + _vm._s(_vm.capitalizeFirstLetter(prescription.prescriptionstatus)) + " (Stripe) ")] : prescription.cancelledbyrole ? [_vm._v(" " + _vm._s(_vm.capitalizeFirstLetter(prescription.prescriptionstatus)) + " (" + _vm._s(_vm.capitalizeFirstLetter(prescription.cancelledbyrole)) + ") ")] : [_vm._v(" " + _vm._s(_vm.capitalizeFirstLetter(prescription.prescriptionstatus)) + " ")]] : [_vm._v(" " + _vm._s(_vm.capitalizeFirstLetter(prescription.prescriptionstatus)) + " ")]], 2)]), _c("span", {
      staticClass: "badge tw-py-1 tw-rounded-full tw-px-2 tw-text-[14px]",
      class: `status-${prescription.airtablestatus && prescription.airtablestatus.replace(/\s+/g, "-").toLowerCase()}`
    }, [_vm._v(" " + _vm._s(prescription.airtablestatus) + " ")])])]), _c("div", {
      staticClass: "tw-flex"
    }, [_c("div", {
      staticClass: "tw-flex tw-gap-8"
    }, [_c("div", [_c("div", {
      staticClass: "tw-text-[#828282] tw-text-xs"
    }, [_vm._v(" Prescribed ")]), _c("div", {
      staticClass: "tw-text-xs"
    }, [_vm._v(" " + _vm._s(prescription.timestampprescibed ? _vm.parseUnixDate(prescription.timestampprescibed, "MM/dd/yy") : "-") + " ")])]), _c("div", [_c("div", {
      staticClass: "tw-text-[#828282] tw-text-xs"
    }, [_vm._v(" Last Refill Ends ")]), _c("div", {
      staticClass: "tw-text-xs"
    }, [_vm._v(" " + _vm._s(prescription.actualenddate ? _vm.parseUnixDate(prescription.actualenddate, "MM/dd/yy") : "-") + " ")])]), prescription.refills - prescription.paidrefills > 0 ? _c("div", [_c("div", {
      staticClass: "tw-text-[#828282] tw-text-xs"
    }, [_vm._v(" Next Refill ")]), _c("div", {
      staticClass: "tw-flex tw-items-center"
    }, [_c("p", {
      staticClass: "mb-0 mr-2 tw-text-xs"
    }, [_vm._v(" " + _vm._s(prescription.nextshipmentdate && prescription.transactiontype !== "one_time" ? _vm.parseUnixDate(prescription.nextshipmentdate, "MMMM d, yyyy") : "-") + " ")])])]) : _vm._e()])])]);
  })], 2), _c("b-col", {
    staticStyle: {
      "max-height": "80vh",
      overflow: "auto"
    }
  }, [_c("b-alert", {
    attrs: {
      variant: "warning",
      show: _vm.alert.show
    }
  }, [_vm._v(" " + _vm._s(_vm.alert.message) + " ")]), _c("span", {
    staticClass: "text-primary"
  }, [_vm._v("Logs")]), _c("b-row", [_c("b-col", {
    attrs: {
      cols: "4"
    }
  }, [_c("span", {
    staticClass: "text-small text-primary"
  }, [_vm._v("Order")]), _c("br"), _c("span", [_vm._v(_vm._s(_vm.selectedPrescription.prescriptionorderno))])]), _c("b-col", [_c("span", {
    staticClass: "text-small text-primar"
  }, [_vm._v("Prescription")]), _c("br"), _c("span", [_vm._v(_vm._s(_vm.selectedPrescription.medicinename))])]), _c("b-col", {
    attrs: {
      cols: "3"
    }
  }, [_c("span", {
    staticClass: "text-small text-primar"
  }, [_vm._v("Date Created")]), _c("br"), _c("span", [_vm._v(" " + _vm._s(_vm.formatDate(_vm.selectedPrescription.datecreated, "MM-DD-YYYY")) + " ")])])], 1), _c("b-row", [_c("b-col", {
    attrs: {
      cols: "4"
    }
  }, [_c("span", {
    staticClass: "text-small text-primary"
  }, [_vm._v("Dosage")]), _c("br"), _c("span", [_vm._v(_vm._s(_vm.selectedPrescription.dosage))])]), _c("b-col", [_c("span", {
    staticClass: "text-small text-primary"
  }, [_vm._v("Quantity")]), _c("br"), _c("span", [_vm._v(_vm._s(_vm.selectedPrescription.unit))])]), _c("b-col", {
    attrs: {
      cols: "3"
    }
  }, [_c("span", {
    staticClass: "text-small text-primary"
  }, [_vm._v("Last Order Date")]), _c("br"), _c("span", [_vm._v(" " + _vm._s(_vm.formatDate(_vm.selectedPrescription.timestampupdated * 1000, "MM-DD-YYYY")) + " ")])])], 1), _vm.selectedPrescription && _vm.selectedPrescription.sentto_pharmacy ? _c("b-row", [_c("b-col", {
    attrs: {
      cols: "6"
    }
  }, [_c("span", {
    staticClass: "text-small text-primary"
  }, [_vm._v(" Pharmacy Destination ")]), _c("br"), _c("span", {
    staticClass: "d-block"
  }, [_vm._v(" " + _vm._s(_vm.selectedPrescription.sentto_pharmacy.name) + " ")]), _c("p", {
    staticClass: "mb-0 d-inline-block"
  }, [_vm._v(" " + _vm._s(_vm.selectedPrescription.sentto_pharmacy.address || _vm.selectedPrescription.sentto_pharmacy.address1) + ", " + _vm._s(_vm.selectedPrescription.sentto_pharmacy.city) + ", " + _vm._s(_vm.selectedPrescription.sentto_pharmacy.state) + ", " + _vm._s(_vm.selectedPrescription.sentto_pharmacy.zipcode) + " ")])]), _c("b-col", {
    attrs: {
      cols: "6"
    }
  }, [_c("span", {
    staticClass: "text-small text-primary"
  }, [_vm._v("Pharmacy ID")]), _c("br"), _c("span", [_vm._v(_vm._s(_vm.selectedPrescription.sentto_pharmacyid))])])], 1) : _vm._e(), _c("b-row", [_c("b-col", [_c("b-table", {
    attrs: {
      striped: "",
      small: "",
      fields: _vm.logFields,
      items: _vm.prescriptionLogs
    },
    scopedSlots: _vm._u([{
      key: "cell(timestampcreated)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(_vm.formatLogTime(row.item.timestampcreated)) + " ")];
      }
    }, {
      key: "cell(user)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(_vm.getUserValue(row.item)) + " ")];
      }
    }])
  }), _vm.loading ? _c("b-skeleton") : _vm._e()], 1)], 1)], 1)], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };